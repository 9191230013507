import { getApiUrl } from '@/app/api/utils'
import { createAxiosClient } from '@/shared/services/createAxiosClient'

type UserSessionResponse = {
  name: string | null
  error?: string
}

export const getSessionUsername = async (): Promise<string | null> => {
  try {
    const response = await createAxiosClient().get<UserSessionResponse>(
      getApiUrl('get-session-username'),
    )
    if (response.data.error) {
      return null
    }
    return response.data.name
  } catch {
    return null
  }
}
