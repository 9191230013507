'use client'

import { useTranslations } from 'next-intl'

import { Box, ContentText, px2rem, spacing } from '@fortum/elemental-ui'

import { browserEnvs } from '@/shared/envs'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'
import { logError } from '@/shared/utils/error'
import { getAdditionalPrices } from '@/shared/utils/tariffElementUtils'

import { Divider } from '../Divider'
import { AdditionalPrices } from '../Price/FinnishPrice/AdditionalPrices'
import { validateCustomerType } from '../Price/utils'
import { StyledHeading } from '../StyledHeading'
import { ComparativePrice } from './parts/ComparativePrice'
import { PriceDetails } from './parts/PriceDetails'

type PriceBreakdownProps = {
  contractTemplate: EnrichedContractTemplate

  /**
   * Selected addons tariff numbers
   **/
  addonsTariffNos: number[]
}

/**
 * Used to present price estimated calculation details and comparison price details.
 */
export const PriceBreakdown = ({ contractTemplate, addonsTariffNos }: PriceBreakdownProps) => {
  const t = useTranslations('purchaseFlow')
  const theme = useTheme()

  const { error: contractError } = validateCustomerType(contractTemplate)

  if (contractError) {
    throw logError(contractError)
  }

  const customerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType
  const country = browserEnvs.NEXT_PUBLIC_COUNTRY
  const comparisonPriceSourceDescription =
    contractTemplate.priceType === 'FIXED' ? 'fixedPrice' : 'spotPrice'
  const { nightPrices, dayPrices, winterPrices, otherSeasonPrices, monthlyFees } =
    getAdditionalPrices(contractTemplate)

  //We don't want to present price breakdown for other contract types
  if (
    contractTemplate.priceType !== 'FIXED' &&
    contractTemplate.priceType !== 'HYBRID' &&
    contractTemplate.priceType !== 'SPOT'
  ) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" gap={spacing.m}>
      <Box display="flex" flexDirection="column" gap={spacing.xxs}>
        <StyledHeading textAlign="center" level={4}>
          {t('priceBreakdown.priceDetails.title')}
        </StyledHeading>
        <ContentText>
          {t(`priceBreakdown.priceDetails.description.${contractTemplate.priceType}`)}
        </ContentText>
        <PriceDetails
          contractTemplate={contractTemplate}
          customerType={customerType}
          addonsTariffNos={addonsTariffNos}
        />
      </Box>
      {country === 'FI' && (nightPrices || dayPrices || winterPrices || otherSeasonPrices) && (
        <>
          <Divider
            color={{ line: theme.colors.borderPrimary }}
            variant="solid"
            verticalSpacing="none"
            height={px2rem(2)}
            data-test="additional-prices-divider"
          />
          <AdditionalPrices
            dayPrices={dayPrices}
            winterPrices={winterPrices}
            nightPrices={nightPrices}
            otherSeasonPrices={otherSeasonPrices}
            monthlyFees={monthlyFees}
            customerType={contractTemplate.customerType}
          />
        </>
      )}
      {country !== 'FI' && (
        <>
          <Divider
            color={{ line: theme.colors.borderPrimary }}
            variant="solid"
            verticalSpacing="none"
            height={px2rem(2)}
          />
          <Box display="flex" flexDirection="column" gap={spacing.xxs}>
            <StyledHeading textAlign="center" level={4}>
              {t('priceBreakdown.comparisonPrice.title')}
            </StyledHeading>
            <ContentText>{t(`priceBreakdown.comparisonPrice.description`)}</ContentText>
            <ComparativePrice contractTemplate={contractTemplate} />
            <ContentText>
              {t(
                `priceBreakdown.comparisonPrice.comparisonPriceSourceDescription.${comparisonPriceSourceDescription}`,
                {
                  customerType:
                    contractTemplate.customerType === 'ENTERPRISE' ? 'enterprise' : 'private',
                },
              )}
            </ContentText>
          </Box>
        </>
      )}
    </Box>
  )
}
