import { Box, ContentText, IconError, Section, spacing } from '@fortum/elemental-ui'

import { lightColors } from '@/shared/utils/colors'
import { parseSource } from '@/shared/utils/errorClasses'

import type { FallbackComponentType } from '../ErrorBoundary/types'

const SECTION_DEFAULT = 'Undefined section'
const MESSAGE_DEFAULT = 'Rendering error occurred'

/**
 * A component to show in place of a section if there is an error in the data and the section cannot be rendered.
 */
export const SectionError: FallbackComponentType = ({
  source = MESSAGE_DEFAULT,
  name = SECTION_DEFAULT,
  appEnv,
}) => {
  /**
   * Would be great to render empty section in production,
   * but we don't have access to app env in mixed environment.
   */

  const parsedSource = parseSource(source)

  if (appEnv === 'prd') {
    /**
     * We don't want to log internal error log messages to customers, for now we will return null
     * It would be good to present some designed missing content component instead of null
     */
    return null
  }

  return (
    <Section>
      <Box
        paddingHorizontal={spacing.s}
        paddingVertical={spacing.xs}
        borderRadius={spacing.xxxs}
        background={lightColors.backgroundWarning}
        textColor={lightColors.textWarning}
        display="flex"
        alignItems="center"
        gap={spacing.xxs}
      >
        <IconError />
        <ContentText color="inherit">
          {name}: {parsedSource.message}
        </ContentText>
      </Box>
    </Section>
  )
}
