'use client'

import { useEffect } from 'react'

import { Box, Col, ContentText, Row, spacing } from '@fortum/elemental-ui'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type {
  CampaignConfigurationEntry,
  ContractHeroSectionEntry,
} from '@/shared/contentful/types'
import { browserEnvs } from '@/shared/envs'
import type {
  EnrichedContractProduct,
  EnrichedContractTemplate,
} from '@/shared/services/campaignDataResolver'
import { useGlobalStore } from '@/shared/store/provider'
import type { CustomerType } from '@/shared/store/slices/selectedContractSlice'
import { logError } from '@/shared/utils/error'
import {
  getCurrency,
  getPriceElements,
  mapContractItemToGTM,
  useDeliveryInfo,
} from '@/shared/utils/gtm/gtmHelper'
import { contractItemsEvent } from '@/shared/utils/sendGTMEvent'

import { BulletList, ListItem } from '../../BulletList'
import { ContentfulImage } from '../../ContentfulImage'
import { FinnishPrice, Price } from '../../Price'
import { validateCustomerType } from '../../Price/utils'
import { useSelectedAddonsTariffNos } from '../../PriceBreakdown/hooks/useSelectedAddonsTariffNos'
import { PricePortal } from '../../PricePortal'
import { PurchaseButton } from '../../PurchaseButton'
import { StyledHeading } from '../../StyledHeading'

export type ContractHeroProps = Omit<
  ContractHeroSectionEntry,
  'contract' | 'campaignConfiguration' | 'sys'
> & {
  contractTemplate: EnrichedContractTemplate
  contractProduct: EnrichedContractProduct
  campaignConfiguration: CampaignConfigurationEntry
}

/**
 * A hero component to be used in the section for contract pages.
 */
export const ContractHero: React.FC<ContractHeroProps> = (props) => {
  const { image, contractProduct, contractTemplate, campaignConfiguration } = props
  const { description, sellingPoints } = contractTemplate.cfData
  const {
    cfData: { title },
  } = contractProduct
  const uspList = sellingPoints?.map((sellingPoint, i) => (
    <ListItem key={i}>{sellingPoint}</ListItem>
  ))

  const { error: contractError } = validateCustomerType(contractTemplate)

  if (contractError) {
    throw logError(contractError)
  }

  const customerType = contractTemplate.customerType.toLocaleLowerCase() as CustomerType

  const { deliveryArea, estimatedConsumption, deliveryStartDate } = useDeliveryInfo()

  const { customerType: gtmCustomerType } = useGlobalStore((store) => store.gtm)

  const { selectedAddonsTariffNos } = useSelectedAddonsTariffNos({ contractTemplate })

  const country = browserEnvs.NEXT_PUBLIC_COUNTRY
  const PriceComponent = country === 'FI' ? FinnishPrice : Price

  useEffect(() => {
    const priceElements = getPriceElements(
      contractTemplate,
      deliveryArea,
      estimatedConsumption,
      selectedAddonsTariffNos,
      deliveryStartDate,
    )
    const mappedContractItem = mapContractItemToGTM(
      contractTemplate,
      priceElements,
      null,
      gtmCustomerType,
    )

    contractItemsEvent(
      'view_item',
      {
        value: getPriceBaseOnCustomerType(
          priceElements.energyPriceElements?.estimatedMonthlyCostWithDiscounts,
          customerType,
        ),
        currency: getCurrency(deliveryArea),
      },
      [mappedContractItem],
    )
  }, [contractTemplate])

  return (
    <Row>
      <Col l={6} xl={5} marginBottom={{ default: spacing.xs, l: 'none' }}>
        {image && (
          <ContentfulImage
            wrapperProps={{
              position: 'relative',
              width: '100%',
              height: { default: '240px', m: '360px', l: '100%' },
            }}
            imageEntry={image}
            style={{ objectFit: 'contain', objectPosition: 'top center' }}
            priority
            maxWidth="900"
          />
        )}
      </Col>
      <Col l={6} xl={7} gap={spacing.xs} display="flex" flexDirection="column">
        <StyledHeading level={1}>{title}</StyledHeading>
        {description && <ContentText>{description}</ContentText>}
        {uspList && <BulletList>{uspList}</BulletList>}

        <PricePortal />

        <PriceComponent
          contractTemplate={contractTemplate}
          campaignConfiguration={campaignConfiguration}
          contractProduct={contractProduct}
          priceBreakdownMode="withFooter"
          shouldShowPricePlaceholder
        />

        <Box textAlign={{ default: 'center', m: 'left' }}>
          <PurchaseButton
            contractTemplate={contractTemplate}
            campaignConfiguration={campaignConfiguration}
            contractProduct={contractProduct}
          />
        </Box>
      </Col>
    </Row>
  )
}
