import { signIn } from 'next-auth/react'

import { countryConfig } from '../countryConfig'
import { routes } from '../routes'

export const signInHandler = async (providerId: string) => {
  await signIn(providerId, {
    callbackUrl: `${countryConfig.basePath}${routes.LOGGED_IN_OVERVIEW}?gtmLog=true&getTerms=true`,
  })
}
