import type React from 'react'
import styled, { css } from 'styled-components'

import { Box, ContentText, opacify, spacing, styles } from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'

export type MenuLinkProps = {
  /**
   * Text of the button
   */
  primaryText: string
  /**
   * Url of the button link
   */
  url?: string
  /**
   * Url target, should the site open in different window or the same
   */
  target?: string
  /**
   * Title of the button link
   */
  title?: string
  /**
   * Flag to specify if button is active
   */
  active?: boolean
  /**
   * Data test id
   */
  dataTestId?: string
}

/**
 * Common use button for navigation menu in all container based variants
 */
export const MenuLink = ({
  primaryText,
  url,
  target,
  title,
  active = false,
  dataTestId,
}: MenuLinkProps) => {
  if (!url) {
    return null
  }

  return (
    <MenuLinkStyled
      data-testid={dataTestId}
      tabIndex={0}
      href={url}
      target={target}
      aria-label={primaryText}
      title={title}
      noUnderlineHover
    >
      <EffectBox $isActive={active}>
        <Box
          role="link"
          display="flex"
          marginHorizontal={{ default: `-${spacing.xs}`, xl: 0 }}
          ph={{ default: spacing.xs, xl: spacing.xxs }}
          pv={{ default: spacing.xxs }}
        >
          <StyledText>{primaryText}</StyledText>
        </Box>
      </EffectBox>
    </MenuLinkStyled>
  )
}

const EffectBox = styled(Box)<{ $isActive?: boolean }>`
  ${({ $isActive, theme }) => css`
    ${$isActive
      ? css`
          div {
            border-left: ${spacing.xxxxs} solid ${theme.colors.brandPrimary};
            background-color: ${opacify(theme.colors.brandPrimary, 0.05)};
          }
        `
      : ``}
  `}
`

const StyledText = styled(ContentText)`
  ${({ theme }) => css`
    ${styles.breakpoint.xs} {
      color: ${theme.colors.textPrimary};
    }
    ${styles.breakpoint.xl} {
      color: ${theme.colors.textSecondary};
    }
  `}
`

const MenuLinkStyled = styled(StyledLink)`
  ${({ theme }) => css`
    &:hover > div,
    &:focus > div,
    &:active > div {
      background-color: ${opacify(theme.colors.brandPrimary, 0.05)};

      p {
        color: ${theme.colors.textPrimary};
        background: none;
      }
    }
  `}
`
