import { browserEnvs } from '@/shared/envs'
import type { EnrichedContractProduct } from '@/shared/services/campaignDataResolver'
import { SalesCampaignMisconfigurationError } from '@/shared/utils/errorClasses'

import type { HousingOptions } from '../components/QuickSearch/types'

const MAX_YEARLY_CONSUMPTION_FOR_APARTMENT = 8000

/**
 * In Sweden we need to select different contract base on the estimatedConsumption for B2C.
 *
 * >=MAX_YEARLY_CONSUMPTION_FOR_APARTMENT then villa
 * <MAX_YEARLY_CONSUMPTION_FOR_APARTMENT then apartment
 */
const getContractHousingTypeByConsumption = (
  estimatedConsumption?: number,
): Extract<HousingOptions, 'apartment' | 'villa'> => {
  if (
    estimatedConsumption !== undefined &&
    estimatedConsumption >= MAX_YEARLY_CONSUMPTION_FOR_APARTMENT
  ) {
    return 'villa'
  }

  return 'apartment'
}

/**
 * Method that pick right contract template from contract product base on campaignId and estimated consumption
 * - no contract templates in contract product -> throw error
 * - only one contract with same campaign id -> return
 * - more than one contract with same campaign id -> return base on the estimatedConsumption
 * - otherwise throw error (something unexpected has happened)
 */
export const getActiveContractTemplate = (
  contractProduct: EnrichedContractProduct,
  campaignId?: number | null,
  estimatedConsumption?: number,
) => {
  const campaignsContractTemplates = contractProduct.contractTemplates?.filter(
    (ct) => ct.campaignId === campaignId,
  )

  const errorDetails = {
    campaignId,
    contractProductName: contractProduct?.cfData?.name,
  }

  if (campaignsContractTemplates?.length === 0) {
    throw new SalesCampaignMisconfigurationError(
      `Can't select contract template from contract product to show price, there is no contract template for campaignId: ${campaignId}`,
      errorDetails,
    )
  }

  if (campaignsContractTemplates?.length === 1) {
    return campaignsContractTemplates[0]
  }

  if (browserEnvs.NEXT_PUBLIC_COUNTRY == 'SE') {
    const housingType = getContractHousingTypeByConsumption(estimatedConsumption)

    if (housingType) {
      const contractTemplate = campaignsContractTemplates?.find((ct) =>
        ct.cfData?.houseType?.includes(housingType),
      )

      if (contractTemplate) {
        return contractTemplate
      }

      throw new SalesCampaignMisconfigurationError(
        `Can't select contract template from contract product to show price, there is more than one contract template using same housing type`,
        errorDetails,
      )
    }
  }

  throw new SalesCampaignMisconfigurationError(
    `Can't select contract template from contract product to show price there is more than one match.`,
    errorDetails,
  )
}
