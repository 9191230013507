'use client'

import { useMemo, useRef } from 'react'
import styled, { css, useTheme } from 'styled-components'

import {
  Box,
  ButtonPill,
  lineHeights,
  opacify,
  px2rem,
  spacing,
  styles,
} from '@fortum/elemental-ui'

import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import { HighlightedHeading } from '@/shared/components/HighlightedHeading'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { HeroSectionEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { Link } from '@/shared/navigation'

export type BasicHeroProps = Omit<HeroSectionEntry, '__typename' | 'sys'>

/**
 * Hero section.
 */
export const BasicHero: React.FC<BasicHeroProps> = (props) => {
  const { colors } = useTheme()
  const headingRef = useRef<HTMLDivElement>(null)
  const isLongWord = useMemo(() => props.title && !props.title.includes(' '), [props.title])

  const gridTemplateColumns = useMemo(() => {
    return props.image
      ? {
          l: `5rem calc(calc(100% - 10rem) / 12 * 5) calc(calc(100% - 10rem) / 12 * 7) 5rem`,
          xxl: `1fr calc(80rem / 12 * 5) calc(80rem / 12 * 7) 1fr`,
        }
      : {
          l: `5rem 1fr 5rem`,
          xxl: `1fr 80rem 1fr`,
        }
  }, [props.image])

  return (
    <Box
      display={{ default: 'flex', l: 'grid' }}
      flexDirection="column-reverse"
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows="1fr"
      backgroundColor={colors.backgroundLightOnDark}
      position="relative"
      overflow="hidden"
    >
      <Box
        gridColumn={{ l: 2 }}
        pt={{
          default: props.image ? 'none' : spacing.m,
          s: props.image ? 'none' : spacing.xl,
          l: spacing.xl,
        }}
        pb={{ default: spacing.m, s: spacing.xl }}
        zIndex={1}
        pl={{
          default: spacing.xxs,
          l: spacing.none,
        }}
        pr={{
          default: spacing.xxs,
          l: props.image ? '0.75rem' : spacing.none,
        }}
      >
        <Box maxWidth={{ xs: px2rem(330), s: px2rem(480), l: 'none' }} mh="auto">
          {props.title && (
            <div ref={headingRef}>
              <HighlightedHeading
                whiteSpace={{ default: 'normal', l: isLongWord ? 'nowrap' : 'normal' }}
                lineHeight={lineHeights.compact}
                level={1}
                text={props.title}
                mb={spacing.xxs}
              />
            </div>
          )}
          {props.subtitle && (
            <StyledHeading
              tag="p"
              level={5}
              color={colors.textLightOnDark}
              mb={spacing.xxs}
              mt={`-${spacing.xxxs}`}
            >
              {props.subtitle}
            </StyledHeading>
          )}
          <Box textColor={colors.textLightOnDark}>
            <RichText document={props.content} />

            {(props.primaryLink || props.secondaryLink) && (
              <Box
                display="flex"
                flexDirection={{ default: 'column', s: 'row' }}
                flexWrap="wrap"
                mt={spacing.m}
                gap={{ default: spacing.xs, l: spacing.xxs }}
              >
                {props.primaryLink && (
                  <ButtonPill
                    tag={Link}
                    href={getLinkEntryUrl(props.primaryLink)}
                    boxShadow={`0 0 ${px2rem(27)} ${px2rem(8)} rgba(255, 255, 255, 0.3)`}
                    data-testid="hero-primary-button"
                    invert
                    transparent
                  >
                    {props.primaryLink.label}
                  </ButtonPill>
                )}
                {props.secondaryLink && (
                  <ButtonPill
                    tag={Link}
                    href={getLinkEntryUrl(props.secondaryLink)}
                    data-testid="hero-secondary-button"
                    status="secondary"
                    invert
                    transparent
                  >
                    {props.secondaryLink.label}
                  </ButtonPill>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {props.image && (
        <Box
          gridColumn={{ l: '3 / 6' }}
          position="relative"
          height={{ default: px2rem(220), s: px2rem(440), l: '100%' }}
          maxHeight={{ default: px2rem(220), s: px2rem(440), l: '100%' }}
        >
          <ContentfulImage2
            priority
            fill
            image={props.image}
            boxProps={{ maxWidth: px2rem(1400), borderRadius: 0 }}
            maxWidth="1400"
          />
          <Curve />
        </Box>
      )}
    </Box>
  )
}
const Curve = styled.div`
  position: absolute;
  z-index: 0;

  ${styles.breakpointMax.l} {
    right: 0;
    bottom: 0;
    left: 0;
    height: ${px2rem(100)};
    margin-bottom: -2px;
    clip-path: ellipse(60% 140% at 50% 140%);
    ${({ theme }) => css`
      background: linear-gradient(
        to top,
        ${theme.colors.backgroundLightOnDark},
        ${opacify(theme.colors.backgroundLightOnDark, 0.85)}
      );
    `};
  }

  ${styles.breakpointMax.s} {
    height: ${px2rem(60)};
  }

  ${styles.breakpoint.l} {
    top: 0;
    bottom: 0;
    left: 0;
    width: ${px2rem(100)};
    margin-left: -2px;
    background-color: ${({ theme }) => opacify(theme.colors.backgroundLightOnDark, 0.85)};
    clip-path: ellipse(130% 60% at -32% 50%);
    ${({ theme }) => css`
      background: linear-gradient(
        to right,
        ${theme.colors.backgroundLightOnDark},
        ${opacify(theme.colors.backgroundLightOnDark, 0.85)}
      );
    `};
  }
`
