import type { Resolver } from '@trpc/client'
import type { AnyProcedure, ProcedureArgs } from '@trpc/server'
import type { inferTransformedProcedureOutput } from '@trpc/server/shared'

import { logError } from '@/shared/utils/error'
import { NetworkError } from '@/shared/utils/errorClasses'

type InferProcedureFromResolver<TResolver extends Resolver<AnyProcedure>> =
  TResolver extends Resolver<infer TProcedure> ? TProcedure : never

type InferArgsFromResolver<TResolver extends Resolver<AnyProcedure>> = ProcedureArgs<
  InferProcedureFromResolver<TResolver>['_def']
>
type InferOutputFromResolver<TResolver extends Resolver<AnyProcedure>> =
  inferTransformedProcedureOutput<InferProcedureFromResolver<TResolver>>

export const fetchDataWithTimeout = async <TResolver extends Resolver<AnyProcedure>>(
  timeout: number,
  trpcResolver: TResolver,
  input: InferArgsFromResolver<TResolver>[0] = undefined,
  options: InferArgsFromResolver<TResolver>[1] = {},
): Promise<InferOutputFromResolver<TResolver>> => {
  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), timeout)
  try {
    return await trpcResolver(input, {
      ...options,
      signal: controller.signal,
    })
  } catch (error) {
    throw logError(new NetworkError(error, undefined))
  } finally {
    clearTimeout(timeoutId)
  }
}
