import type { ZodType } from 'zod'

import { getFullKey } from './localStorage'

/**
 * Get a value from local storage, parsing it with the provided schema and
 * returning a default value if the value is not found or invalid.
 */
export const getSessionStorageValue = <T>(key: string, schema: ZodType<T>, defaultValue: T): T => {
  if (typeof window === 'undefined') {
    return defaultValue
  }
  const storedData = window.sessionStorage.getItem(getFullKey(key))
  if (!storedData) {
    return defaultValue
  }
  try {
    return schema.parse(JSON.parse(storedData))
  } catch {
    return defaultValue
  }
}

export const setSessionStorageValue = (key: string, value: unknown) => {
  if (typeof window === 'undefined') {
    return
  }
  window.sessionStorage.setItem(getFullKey(key), JSON.stringify(value))
}
