import type { CreateAxiosDefaults } from 'axios'
import axios from 'axios'

const REQUEST_TIMEOUT = 30_000

/**
 * Axios instance create with common options. Should be used as a default way to create axios instance.
 * @param config
 * @returns
 */
export const createAxiosClient = (config?: CreateAxiosDefaults) =>
  axios.create({
    timeout: REQUEST_TIMEOUT,
    ...config,
  })
