import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import { validateCoopMembershipId } from '@/open-web/services/validateCoopMembershipId'
import { logError } from '@/shared/utils/error'
import { ValidationError } from '@/shared/utils/errorClasses'

import { middleware } from '../middleware'
import type { Store } from '../types'

export type MembershipIdStatus = 'valid' | 'invalid' | 'verifying' | 'verificationFailed'

type PartnerFieldsState = {
  /**
   * Coop customer id needs to be stored in sessions storage, otherwise after bank id identification will be lost
   */
  coopMembershipId?: string
  coopMembershipIdStatus?: MembershipIdStatus
  euroBonus?: string
  isEuroBonusValid?: boolean
}

type PartnerFieldsActions = {
  clearCoopMembershipIdCode: () => void
  setCoopMembershipIdCode: (code?: string) => void
  setCoopMembershipIdStatus: (status: MembershipIdStatus) => void
  clearEuroBonusCode: () => void
  setEuroBonusCode: (code: string) => void
  setIsEuroBonusCodeValid: (isValid: boolean) => void
}

export type PartnerFieldStore = {
  partnerFields: PartnerFieldsState & PartnerFieldsActions
}

// Good practice to keep it still, even while empty
export const initialState: PartnerFieldsState = {}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], PartnerFieldStore> = (
  set,
  get,
) => ({
  partnerFields: {
    ...initialState,
    clearCoopMembershipIdCode: () => {
      set(
        produce(get(), (state: Store) => {
          state.partnerFields.coopMembershipId = undefined
        }),
      )
    },
    setCoopMembershipIdCode: (code) => {
      set(
        produce(get(), (state: Store) => {
          state.partnerFields.coopMembershipId = code
        }),
      )
    },
    setCoopMembershipIdStatus: (status: MembershipIdStatus) => {
      set(
        produce(get(), (state: Store) => {
          state.partnerFields.coopMembershipIdStatus = status
        }),
      )
    },
    clearEuroBonusCode: () => {
      set(
        produce(get(), (state: Store) => {
          state.partnerFields.euroBonus = undefined
        }),
      )
    },
    setEuroBonusCode: (code) => {
      set(
        produce(get(), (state: Store) => {
          state.partnerFields.euroBonus = code
        }),
      )
    },
    setIsEuroBonusCodeValid: (isValid) => {
      set(
        produce(get(), (state: Store) => {
          state.partnerFields.isEuroBonusValid = isValid
        }),
      )
    },
  },
})

export const createPartnerFieldsSlice = middleware(creator, (prevState, _set, get, _store) => {
  const prevEuroBonus = prevState.partnerFields.euroBonus
  const prevCoopMembershipId = prevState.partnerFields.coopMembershipId
  const { euroBonus, coopMembershipId, setIsEuroBonusCodeValid, setCoopMembershipIdStatus } =
    get().partnerFields
  if (euroBonus && prevEuroBonus !== euroBonus) {
    setIsEuroBonusCodeValid(/^(\d){9}$/.test(euroBonus))
  }

  if (coopMembershipId && prevCoopMembershipId !== coopMembershipId) {
    setCoopMembershipIdStatus('verifying')
    validateCoopMembershipId(coopMembershipId)
      .then((response) => {
        setCoopMembershipIdStatus(response.status ? 'valid' : 'invalid')
      })
      .catch(() => {
        const validationError = new ValidationError(
          'Error validating Coop Membership ID',
          'Coop Membership ID Input',
          ['coop'],
        )
        logError(validationError)
        setCoopMembershipIdStatus('verificationFailed')
      })
  }
})
