import styled from 'styled-components'

import { Tr as ElementalTr, styles } from '@fortum/elemental-ui'

export const RotatedImg = styled.img`
  transform: rotate(180deg);
  margin-bottom: -2px;
`

export const StyledTr = styled(ElementalTr)`
  ${styles.breakpoint.s} {
    .leftAligned {
      text-align: left;
      padding-left: 36px;
    }
  }
`

export * from '@/logged-in/components/Energy/EnergyTable/styled'
