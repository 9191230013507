import { createTRPCProxyClient, createTRPCReact, httpLink } from '@trpc/react-query'
import superjson from 'superjson'

import type { AppRouter } from '@/shared/trpc/routers/app'

import { countryConfig } from '../countryConfig'

export const trpc = createTRPCReact<AppRouter>()

const url = `${countryConfig.basePath}/api/trpc/`

// This client can only be used in the client components! For server use server actions or as last resort use getTrpcHelpers
export const vanillaTRPCClient = createTRPCProxyClient<AppRouter>({
  links: [
    httpLink({
      url,
    }),
  ],
  transformer: superjson,
})
