import { v4 as uuid } from 'uuid'

import type {
  GlobalApiBaseErrorCode,
  GlobalApiServerError as GlobalApiErrorData,
} from './globalApiErrorHandler'

export const ErrorCodes = {
  UNKNOWN_ERROR: 'unknownError',
  PERMISSION_ERROR: 'permissionError',
  NETWORK_ERROR: 'networkError',
  VALIDATION_ERROR: 'validationError',
  UI_ERROR: 'uiError',
  SECTION_ERROR: 'sectionError',
  CONTRACT_ERROR: 'contractError',
  SALES_CAMPAIGN_MISCONFIGURATION_ERROR: 'salesCampaignMisconfigurationError',
  ORDER_DATA_ADAPTER_ERROR: 'orderDataAdapterError',
  UNKNOWN_GLOBAL_API_ERROR: 'unknownGlobalApiError',
  GLOBAL_API_ERROR: 'globalApiError',
  UNKNOWN_NEXT_API_ROUTE_ERROR: 'unknownNextApiRouteError',
  SERVER_COMPONENT_ERROR: 'serverComponentError',
  CONTENTFUL_API_ERROR: 'contentfulApiError',
  ORDER_CHECKOUT_ERROR: 'orderCheckoutError',
  QUIZ_FLOW_ERROR: 'quizFlowError',
  STORAGE_ERROR: 'storageError',
} as const

export type ErrorCodesMapping = (typeof ErrorCodes)[keyof typeof ErrorCodes]
// ErrorSource is always unknown, remove or re-think, leaving for now to make it cleaner what might be passed as source
export type ErrorSource = Error | string | unknown | BaseError | ReportableError

type BaseErrorProps<T = Record<string, unknown>> = {
  source: ErrorSource
  code: ErrorCodesMapping
  additionalData: T
  name?: string
  cause?: unknown
}

export const parseSource = (
  source: BaseErrorProps['source'],
): { message: string; cause: unknown } => {
  switch (true) {
    case source instanceof Error:
      return { message: source.message, cause: source }
    case typeof source === 'string':
      return { message: source, cause: undefined }
    default:
      return { message: 'Unknown error occured', cause: source }
  }
}

export class BaseError<T = Record<string, unknown>> extends Error {
  code: ErrorCodesMapping
  additionalData: T
  readonly id: string

  constructor(props: BaseErrorProps<T>) {
    const { source, code, additionalData, name } = props
    const { message, cause } = parseSource(source)

    super(message, { cause })

    this.id = uuid()
    this.code = code
    this.additionalData = additionalData
    this.name = name || 'UnknownError'

    // To include the message in the JSON representation
    Object.defineProperty(this, 'message', {
      enumerable: true,
    })
  }

  toJSON() {
    return {
      id: this.id,
      code: this.code,
      message: this.message,
      name: this.name,
      additionalData: this.additionalData,
    }
  }
}

export class ReportableError<T = Record<string, unknown>> extends BaseError<T> {
  reported: boolean
  constructor(props: BaseErrorProps<T>) {
    super(props)

    // Needed to avoid double reporting if error has been re-thrown
    this.reported =
      props.source instanceof ReportableError ? (props.source.reported ?? false) : false
  }
}

class NonReportableError<T> extends BaseError<T> {
  constructor(props: BaseErrorProps<T>) {
    super(props)
  }
}

export class NetworkError extends ReportableError<{ httpCode: number | undefined }> {
  constructor(source: BaseErrorProps['source'], httpCode: number | undefined) {
    super({
      source,
      code: ErrorCodes.NETWORK_ERROR,
      name: 'NetworkError',
      additionalData: { httpCode },
    })
  }
}

export class PermissionError extends ReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source']) {
    super({
      source,
      name: 'PermissionError',
      code: ErrorCodes.PERMISSION_ERROR,
      additionalData: {},
    })
  }
}

export class ValidationError extends ReportableError<{ field: string; issues: string[] }> {
  constructor(source: BaseErrorProps['source'], field: string, issues: string[]) {
    super({
      source,
      name: 'ValidationError',
      code: ErrorCodes.VALIDATION_ERROR,
      additionalData: { field, issues },
    })
  }
}

export class UiError extends NonReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source'], component?: string) {
    super({
      source,
      name: 'UserInterfaceError',
      code: ErrorCodes.UI_ERROR,
      additionalData: {
        component,
      },
    })
  }
}

export class SectionError extends ReportableError<{ sectionType: string }> {
  constructor(source: BaseErrorProps['source'], sectionType: string) {
    super({
      source,
      name: 'SectionError',
      code: ErrorCodes.SECTION_ERROR,
      additionalData: { sectionType },
    })
  }
}

export class ServerComponentError extends ReportableError<{
  sectionType: string
  entryId?: string
}> {
  constructor(source: BaseErrorProps['source'], sectionType: string, entryId?: string) {
    super({
      source,
      name: 'ServerComponentError',
      code: ErrorCodes.SERVER_COMPONENT_ERROR,
      additionalData: { sectionType, entryId },
    })
  }
}

export class PageError extends ReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source'], pageType: string) {
    super({
      source,
      name: 'PageError',
      code: ErrorCodes.SECTION_ERROR,
      additionalData: { pageType },
    })
  }
}

export class OrderCheckoutError extends ReportableError<{
  step: string
  field: string
  debugDetails?: Record<string, unknown>
}> {
  constructor(
    source: BaseErrorProps['source'],
    field: string,
    step: string,
    debugDetails?: Record<string, unknown>,
  ) {
    super({
      source,
      name: 'OrderCheckoutError',
      code: ErrorCodes.ORDER_CHECKOUT_ERROR,
      additionalData: { step, field, debugDetails },
    })
  }
}

export class ContractOrderError extends ReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source']) {
    super({
      source,
      name: 'ContractOrderError',
      code: ErrorCodes.CONTRACT_ERROR,
      additionalData: {},
    })
  }
}

type CampaignMisconfigurationErrorAdditionalData = {
  campaignId?: number | null
  contractProductName?: string | null
  templateNo?: number | null
  tariffNo?: number | null
}

export class SalesCampaignMisconfigurationError extends ReportableError<CampaignMisconfigurationErrorAdditionalData> {
  constructor(
    source: BaseErrorProps['source'],
    additionalData?: CampaignMisconfigurationErrorAdditionalData,
  ) {
    super({
      source,
      name: 'SalesCampaignMisconfigurationError',
      code: ErrorCodes.SALES_CAMPAIGN_MISCONFIGURATION_ERROR,
      additionalData: additionalData ?? {},
    })
  }
}

export class OrderDataAdapterError extends ReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source']) {
    super({
      source,
      name: 'OrderDataAdapterError',
      code: ErrorCodes.ORDER_DATA_ADAPTER_ERROR,
      additionalData: {},
    })
  }
}

export class UnknownGlobalApiError extends ReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source']) {
    super({
      source,
      name: 'UnknownGlobalApiError',
      code: ErrorCodes.UNKNOWN_GLOBAL_API_ERROR,
      additionalData: {},
    })
  }
}

export class QuizFlowError extends ReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source']) {
    super({
      source,
      name: 'QuizFlowError',
      code: ErrorCodes.QUIZ_FLOW_ERROR,
      additionalData: {},
    })
  }
}

export class GlobalApiError<E> extends ReportableError<{
  apiError: GlobalApiErrorData<E>
}> {
  constructor(props: { apiError: GlobalApiErrorData<E> }) {
    const { apiError } = props
    super({
      source: apiError.message || 'Global API error - no message',
      name: 'GlobalApiError',
      code: ErrorCodes.GLOBAL_API_ERROR,
      additionalData: {
        apiError,
      },
    })
  }
}

export class ContentfulApiError extends ReportableError<{
  queryVariables: string
  apiErrorMessage: string
  contentfulRequestId?: string
  queryCost?: string
  persistedQueryHash?: string
}> {
  constructor(props: {
    source: BaseErrorProps['source']
    queryVariables: Record<string, unknown>
    apiErrorMessage: string
    contentfulRequestId?: string
    queryCost?: string
    persistedQueryHash?: string
    queryName?: string
    errorCodes?: string[]
  }) {
    const {
      apiErrorMessage,
      source,
      queryVariables,
      contentfulRequestId,
      persistedQueryHash,
      queryCost,
    } = props
    super({
      source,
      name: 'ContentfulApiError',
      code: ErrorCodes.CONTENTFUL_API_ERROR,
      additionalData: {
        queryVariables: JSON.stringify(queryVariables),
        apiErrorMessage,
        contentfulRequestId,
        persistedQueryHash,
        queryCost,
      },
    })
  }
}

export class StorageError extends NonReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source'], cause?: unknown) {
    super({
      source,
      name: 'StorageError',
      code: ErrorCodes.STORAGE_ERROR,
      additionalData: {
        cause,
      },
    })
  }
}

export class UnknownNextApiRouteError extends ReportableError<Record<string, unknown>> {
  constructor(source: BaseErrorProps['source'], pathname?: string) {
    super({
      source,
      name: 'UnknownNextApiRouteError',
      code: ErrorCodes.UNKNOWN_NEXT_API_ROUTE_ERROR,
      additionalData: {
        pathname,
      },
    })
  }
}

export const isGlobalApiError = <E = GlobalApiBaseErrorCode>(
  error: unknown,
): error is GlobalApiError<E> => (error as GlobalApiError<E>) instanceof GlobalApiError
