import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { Accordion2 } from '@/shared/components/Accordion2'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { InPageFaqEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

type AccordionItemProps = {
  item: InPageFaqEntry
  isExpanded: boolean
  onToggle: () => void
}

export const AccordionItem = ({ item, isExpanded, onToggle }: AccordionItemProps) => {
  const panelId = `faq-panel-mobile-${item.sys.id}`

  const theme = useTheme()

  return (
    <Accordion2
      header={<ContentText>{item.title}</ContentText>}
      panelId={panelId}
      isExpanded={isExpanded}
      onToggle={onToggle}
      boxProps={{
        backgroundColor: theme.colors.backgroundSecondary,
        border: isExpanded
          ? `2px solid ${theme.colors.brandPrimary}`
          : `2px solid ${theme.colors.borderPrimary}`,
        borderRadius: spacing.xxxs,
        ph: spacing.xxs,
        pv: spacing.xxs,
      }}
      headerBoxProps={{
        backgroundColor: theme.colors.backgroundSecondary,
      }}
      headerTestId="in-page-faq-accordion-header"
    >
      <Box mt={{ default: spacing.xs, s: spacing.s }}>
        {item.contentTitle && (
          <StyledHeading color={theme.colors.textPrimary} level={3} styledAs={4} mb={spacing.xxs}>
            {item.contentTitle}
          </StyledHeading>
        )}
        <RichText document={item.content} />
      </Box>
    </Accordion2>
  )
}
