'use client'

import { useTranslations } from 'next-intl'

import { Box, ContentText } from '@fortum/elemental-ui'

import { logError } from '@/shared/utils/error'
import { isEnergyElement, isMonthlyFeeElement } from '@/shared/utils/tariffElementUtils'

import { EnergyPrice } from './EnergyPrice'
import { MonthlyFee } from './MonthlyFee'
import { useSelectedAddonsTariffNos } from '../../PriceBreakdown/hooks/useSelectedAddonsTariffNos'
import { PriceBreakdownInfoIcon } from '../../PriceBreakdownInfoIcon'
import type { PriceProps } from '../types'
import { validateCustomerType } from '../utils'

export const FinnishPrice: React.FC<PriceProps> = ({
  contractTemplate,
  campaignConfiguration,
  contractProduct,
  mode = 'full',
  theme = 'light',
  priceBreakdownMode = 'default',
  priceSize = 'xl',
  isPriceBreakdownDisabled,
}) => {
  const { error: contractError } = validateCustomerType(contractTemplate)

  if (contractError) {
    throw logError(contractError)
  }

  const tPurchaseFlow = useTranslations('purchaseFlow')

  const energyTariffElement = contractTemplate.tariffElements.find((element) =>
    isEnergyElement(element.type),
  )
  const monthlyFeeTariffElement = contractTemplate.tariffElements.find((element) =>
    isMonthlyFeeElement(element.type),
  )

  const { selectedAddonsTariffNos } = useSelectedAddonsTariffNos({ contractTemplate })

  return (
    <Box>
      {mode === 'full' && (
        <PriceBreakdownInfoIcon
          mode={priceBreakdownMode}
          theme={theme}
          contractTemplate={contractTemplate}
          campaignConfiguration={campaignConfiguration}
          contractProduct={contractProduct}
          isDisabled={isPriceBreakdownDisabled}
          addonsTariffNos={selectedAddonsTariffNos}
        >
          <ContentText size="s" color="inherit">
            {tPurchaseFlow('priceDetailsButtonText')}
          </ContentText>
        </PriceBreakdownInfoIcon>
      )}
      {energyTariffElement && contractTemplate.priceType !== 'FLAT_RATE' && (
        <EnergyPrice
          tariffElement={energyTariffElement}
          contractTemplate={contractTemplate}
          isHourlyPriced={contractTemplate.productName.includes('Tarkka')}
          priceSize={priceSize}
          theme={theme}
        />
      )}
      {monthlyFeeTariffElement && (
        <MonthlyFee
          tariffElement={monthlyFeeTariffElement}
          renderLabel={contractTemplate.priceType !== 'FLAT_RATE'}
          contractTemplate={contractTemplate}
          priceSize={priceSize}
          theme={theme}
        />
      )}
    </Box>
  )
}
