import { getApiUrl } from '@/app/api/utils'
import type { ElectricityPriceArea } from '@/shared/graphql/schema/commonBackend/graphql'
import { createAxiosClient } from '@/shared/services/createAxiosClient'

import type { PriceAreaByPostalCodeError } from './types'
import type { GlobalApiResponse } from '../../../shared/utils/globalApiErrorHandler'

/**
 * Client method that return ElectricityPriceArea base on the postal code or street address and place*
 * *required only when postal code belongs to more than one electricity area
 * @param campaignIds
 * @returns
 */

export const getPriceAreaByPostalCode = async (
  postalCode: string,
  streetAddress?: string,
  place?: string,
): Promise<GlobalApiResponse<ElectricityPriceArea, PriceAreaByPostalCodeError>> => {
  const searchParams = new URLSearchParams({
    postalCode: postalCode ?? '',
    streetAddress: streetAddress ?? '',
    place: place ?? '',
  })

  const result = await createAxiosClient().get<
    GlobalApiResponse<ElectricityPriceArea, PriceAreaByPostalCodeError>
  >(getApiUrl(`get-price-area-by-postal-code?${searchParams.toString()}`))

  return result.data
}
