'use client'

import { Col, Row, spacing } from '@fortum/elemental-ui'

import { ContentfulImage2 } from '@/shared/components/ContentfulImage2'
import { SectionError } from '@/shared/components/SectionError'
import { useClientSideConfs } from '@/shared/providers/appEnv'
import { rejectNil } from '@/shared/utils/array'
import { getSizesForColumns } from '@/shared/utils/image'

import { BenefitHeader } from './BenefitHeader'
import { BenefitItem } from './BenefitItem'
import type { CommonBenefitsProps } from '../types'

export type BenefitsBigImageProps = Omit<CommonBenefitsProps, 'layoutReversed' | 'isLayoutReversed'>

/**
 * Variant of the benefits section. Shows primary image with items below it.
 * Each item shows a title, content and image.
 * Min and max number of items are 2 and 3.
 */
export const BenefitsBigImage = ({
  benefitsSectionItemsCollection,
  simpleContent,
  image,
  title,
  topic,
}: BenefitsBigImageProps) => {
  const clientSideConfs = useClientSideConfs()

  const items = benefitsSectionItemsCollection?.items
  const count = items ? items.length : 0

  if (count < 2 || count > 3) {
    return (
      <SectionError
        source="Benefits section big image variant supports only 2 or 3 items."
        name="BenefitsBigImage"
        appEnv={clientSideConfs.appEnv}
      />
    )
  }

  return (
    <Row rowGap={{ default: spacing.xxs, l: spacing.m }}>
      {image && (
        <Col>
          <ContentfulImage2
            image={image}
            boxProps={{ aspectRatio: '16/9', borderRadius: spacing.xxxxs }}
            sizes={getSizesForColumns({ l: 10 })}
          />
        </Col>
      )}
      {(title || simpleContent) && (
        <Col>
          <BenefitHeader
            boxProps={{ textAlign: 'center' }}
            title={title}
            content={simpleContent}
            topic={topic}
          />
        </Col>
      )}
      {items &&
        rejectNil(items).map(({ sys, ...fields }) => (
          <Col key={sys.id} display="flex" flexDirection="column" l={12 / count} gap={spacing.xxs}>
            {fields.image && (
              <ContentfulImage2
                image={fields.image}
                boxProps={{ aspectRatio: '16/9', borderRadius: spacing.xxxxs }}
                sizes={getSizesForColumns({ l: 10 / count })}
              />
            )}
            <BenefitItem title={fields.title} content={fields.content} />
          </Col>
        ))}
    </Row>
  )
}
