import { getApiUrl } from '@/app/api/utils'
import { createAxiosClient } from '@/shared/services/createAxiosClient'

import type { UserStatusSession } from './UserStatus'

export const getUserSessionForGA = async () => {
  try {
    const response = await createAxiosClient().get<UserStatusSession>(
      getApiUrl('get-user-session-for-ga'),
    )
    return response.data
  } catch {
    throw new Error('Failed to fetch user session for GA')
  }
}
