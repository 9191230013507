'use client'

import styled from 'styled-components'

import type { HeadingProps } from '@fortum/elemental-ui'
import { Heading, fontWeights, lineHeights } from '@fortum/elemental-ui'

import { useTheme } from '@/shared/hooks/useTheme'

/**
 * Elemental heading wrapper that applies base styles.
 */
export const StyledHeading = (props: HeadingProps) => {
  const { colors } = useTheme()

  return (
    <HyphenatedHeading
      color={colors.textLarge}
      fontWeight={fontWeights.medium}
      lineHeight={lineHeights.tight}
      textWrap="pretty"
      {...props}
    />
  )
}

const HyphenatedHeading = styled(Heading)`
  hyphens: auto;
`
