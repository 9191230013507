'use client'

import { Section, spacing } from '@fortum/elemental-ui'

import { SectionError } from '@/shared/components/SectionError'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { CtaSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import { useClientSideConfs } from '@/shared/providers/appEnv'

import { CtaGridWithImage } from './parts/CtaGridWithImage'
import { CtaGridWithSecondCta } from './parts/CtaGridWithSecondCta'

export type CtaSectionProps = CtaSectionEntry

/**
 * Section showing one or two call-to-action items.
 */
export const CtaSection = ({
  title,
  ctaSectionItemsCollection,
  colorVariant = 'default',
  reverseLayout = false,
}: CtaSectionProps) => {
  const count = ctaSectionItemsCollection?.items?.length ?? 0
  const primaryCta = ctaSectionItemsCollection?.items?.[0]
  const secondaryCta = ctaSectionItemsCollection?.items?.[1]

  const clientSideConfs = useClientSideConfs()
  const { colors } = useTheme()
  const isSecondary =
    colorVariant === 'secondary' ||
    colorVariant === 'secondaryInverted' ||
    colorVariant === 'secondaryMixed'

  if (count < 1 || count > 2) {
    return (
      <SectionError
        source="CTA section supports 1 or 2 items."
        name="CTA Section"
        appEnv={clientSideConfs.appEnv}
      />
    )
  }

  return (
    <Section
      pv={{ default: spacing.xs, l: spacing.m }}
      backgroundColor={isSecondary ? colors.backgroundSecondary : colors.backgroundPrimary}
    >
      {title && (
        <StyledHeading level={2} styledAs={3} mb={spacing.xs}>
          {title}
        </StyledHeading>
      )}
      {primaryCta && secondaryCta ? (
        <CtaGridWithSecondCta
          primaryCta={primaryCta}
          secondaryCta={secondaryCta}
          colorVariant={colorVariant}
          isLayoutReversed={reverseLayout}
        />
      ) : (
        primaryCta && (
          <CtaGridWithImage
            primaryCta={primaryCta}
            colorVariant={colorVariant}
            isLayoutReversed={reverseLayout}
          />
        )
      )}
    </Section>
  )
}
