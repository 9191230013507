'use client'

import type { PropsWithChildren, ReactNode } from 'react'
import type React from 'react'
import { Component } from 'react'

import type { AppEnv } from '@/shared/envs'
import { logError } from '@/shared/utils/error'
import { UiError } from '@/shared/utils/errorClasses'

import type { FallbackComponentType } from './types'
import { SectionError } from '../SectionError'

interface ErrorBoundaryProps extends PropsWithChildren {
  FallbackComponent?: FallbackComponentType
  name?: string
  appEnv: AppEnv
}

interface ErrorBoundaryState {
  hasError: boolean
  error?: Error
}

/**
 * Class component error boundary to catch component errors in the client browser.
 * Docs: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Required method in error boundary according to docs.
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo): void {
    logError(new UiError(error))

    this.setState({ error })
  }

  render(): ReactNode {
    const { FallbackComponent, name } = this.props
    const { error } = this.state
    if (this.state.hasError) {
      if (!FallbackComponent) {
        return <SectionError source={error} name={name} appEnv={this.props.appEnv} />
      }
      return <FallbackComponent source={error} name={name} appEnv={this.props.appEnv} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
